.content{
    font-size: 130%;
}
.select1{
    width:  12% !important;
    float: right;
    /* margin-right:18%; */
}
.Polaris-Pagination{
    float: left;
    width:  auto !important;
    /* margin-left: 60%; */
}
.data_table{
    margin-top: 20px;
}


.Toggle{
    margin-top: 60px;
}
.userDetails{
    font-size: 150%;
    font-weight: 500;
}
.userHeading{
    font-size: 150%;
    font-weight: 900;
}
.loading{
    height:100px; 
    width:150px;
    /* margin-left: 50%;
    transform: translateX(-20%); */
}
.variant{
    width: 48%;
    float: left;
}
.orders{
    width: 48%;
    float: right;
}